/* General Body Styling */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  line-height: 1.6; 
  font-size: large;
}

/* Additional Styling for Overall Appearance */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700; 
}

/* Section Styling */
section {
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #e6f7ff; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

/* Section Headers */
section h2 {
  color: #FF4500; 
  font-size: 1.5em;
  margin-bottom: 15px;
}

section h1 {
  color: #FF5700; 
  text-align: center;
  font-size: 2.5em;
}

/* Logo Styling */
.logo {
  display: block;
  margin: 0 auto 20px auto;
  max-width: 200px;
}



/* Button Styling */
button.gatheringButton, button.dataButton {
  background-color: #FF5700; 
  color: #ffffff; 
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
  display: block;
  margin: 0 auto;
}

button.gatheringButton:hover, button.dataButton:hover {
  background-color: #FF5700;
}

/* In Progress and Finished Labels */
.inprogress {
  background-color: #cce5ff;
  color: #005b96; 
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
}

.finishedLabel {
  background-color: #d4edda;
  color: #155724; 
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
}

.finishedLabel button.dataButton {
  margin-top: 10px;
  background-color: #28a745; 
}

.finishedLabel button.dataButton:hover {
  background-color: #218838; 
}

/* Track Two Section */
#trackTwo {
  background-color: #e6f2ff; 
  border: 1px solid #b3d7ff; 
  padding: 20px;
  border-radius: 8px;
}


/* Inputs */
.inputField {
  width: 80%; 
  max-width: 600px; 
  padding: 15px;
  margin: 20px 0;
  border: 2px solid #ddd;
  border-radius: 10px;
  font-size: 18px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  outline: none; 
  transition: border-color 0.3s ease, box-shadow 0.3s ease; 
}

.inputField:focus {
  border-color: #007bff; 
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3); 
}


/* notification */

.notification {
  color: #ff0000;
  font-size: 16px;
  margin: 10px 0;
}



/* error */

.errorLabel {
  background-color: #dd6161;
  color: #ffffff; 
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
}


/* warning */

.warningLabel {
  background-color: #ff6600;
  color: #ffffff; 
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
}


